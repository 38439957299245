/* eslint-disable class-methods-use-this */
import Service from '../Service';

class BannersService extends Service {
    constructor() {
        super('/banners');
    }

    getFieldsTable() {
        return [
            {
                key: 'subsee_reference',
                label: 'Referência',
                sortable: true,
                tdClass: 'text-center',
                thClass: 'text-center',
            },
            {
                key: 'client.name',
                label: 'Cliente',
                sortable: true,
            },
            {
                key: 'url',
                label: 'link',
                sortable: true,
            },
            {
                key: 'start_date',
                label: 'Início',
                tdClass: 'text-center',
                thClass: 'text-center',
                sortable: true,
            },
            {
                key: 'end_date',
                label: 'Fim',
                tdClass: 'text-center',
                thClass: 'text-center',
                sortable: true,
            },
            {
                key: 'views',
                label: 'Views',
                tdClass: 'text-center',
                thClass: 'text-center',
                sortable: true,
            },
            {
                key: 'clicks',
                label: 'Clicks',
                tdClass: 'text-center',
                thClass: 'text-center',
                sortable: true,
            },
            {
                key: 'conversion',
                label: 'Conversão',
                tdClass: 'text-center',
                thClass: 'text-center',
                sortable: true,
            }
        ];
    }
}

const bannersService = new BannersService();
export default bannersService;

<template>
  <div>
    <loading :enable="loading" />
    <b-row class="card mb-5">
      <h5 class="title-card"><i class="fas fa-filter"></i> Filtros</h5>
      <b-row class="px-3">
        <b-col lg="3">
          <b-form-group label="Cliente">
            <vue-bootstrap-typeahead
                :data="clients"
                v-model="clientSearch"
                :serializer="s => s.name"
                placeholder="Digite o nome ou código do cliente"
                @hit="filter.advertiser = $event.value"
                :minMatchingChars="1"
            />
          </b-form-group>
        </b-col>
        <b-col lg="3">
          <b-form-group label="Início">
            <b-form-datepicker v-model="filter.start" :locale="'pt-br'"  class="mb-2"></b-form-datepicker>
          </b-form-group>
        </b-col>
        <b-col lg="3">
          <b-form-group label="Fim">
            <b-form-datepicker v-model="filter.end" :locale="'pt-br'" class="mb-2"></b-form-datepicker>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="Situação">
            <b-form-select v-model="filter.situation">
              <b-form-select-option>Todos</b-form-select-option>
              <b-form-select-option :value="'active'">Vigentes</b-form-select-option>
              <b-form-select-option :value="'inactive'">Expirados</b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>

      <b-col lg="12" class="py-3">
        <b-button @click="handleFilter" variant="primary" class="mr-2" :disabled="loading">
          <b-spinner small v-if="loading"></b-spinner>
          <i class="fas fa-search" v-if="!loading"></i> Pesquisar
        </b-button>
      </b-col>
    </b-row>

    <b-row class="card mb-5" v-if="totalErrors > 0 || total > 0 || totalVintage > 0">
      <b-row class="p-3">
        <b-col>
          <div class="widget d-flex w-100 warning">
            <div class="d-flex align-items-start justify-content-between flex-column w-100">
              <span class="title">Imóveis no Vintage</span>
              <span class="value">{{ totalVintage }}</span>
            </div>
          </div>
        </b-col>

        <b-col>
          <div class="widget d-flex w-100 primary">
            <div class="d-flex align-items-start justify-content-between flex-column w-100">
              <span class="title">Imóveis no SUB100</span>
              <span class="value">{{ total }}</span>
            </div>
          </div>
        </b-col>

        <b-col>
          <div class="widget d-flex w-100 danger">
            <div class="d-flex align-items-start justify-content-between flex-column w-100">
              <span class="title">Erros da última importação</span>
              <span class="value">{{ totalErrors }}</span>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-row>

    <b-row class="card mb-5">
      <div class="w-100 p-3">
        <b-table
            responsive
            class="mt-3"
            :items="items"
            :fields="fields"
            striped
            bordered
            hover
            show-empty
        >
          <template v-slot:empty>
            <h4 class="text-center">Nenhum Resultado encontrado</h4>
          </template>

          <template v-slot:table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong>Carregando...</strong>
            </div>
          </template>

          <template v-slot:cell(start_date)="data">
            {{ data.item.start_date | moment("DD/MM/YYYY") }}
          </template>

          <template v-slot:cell(end_date)="data">
            {{ data.item.end_date | moment("DD/MM/YYYY") }}
          </template>
        </b-table>
      </div>
    </b-row>
  </div>
</template>

<script>
import HighlightService from "@/services/highlights/HighlightService";
import _ from "underscore";
import Loading from "@/components/shared/loading";
import bannersService from "@/services/banners/BannersService";
import  {startOfMonth, endOfMonth, format} from "date-fns";
export default {
  components: {Loading},
  data() {
    return {
      loading: false,
      totalVintage: 0,
      total: 0,
      totalErrors: 0,
      fields: bannersService.getFieldsTable(),
      clients: [],
      clientSearch: '',
      clientCities: [],
      items: [],
      filter: {
        situation: 'active',
        advertiser: null,
      }
    }
  },
  async created() {

    this.$nextTick(() => {
      this.filter.start = format(startOfMonth(new Date()), "yyyy-MM-dd");
      this.filter.end = format(endOfMonth(new Date()), "yyyy-MM-dd");
      this.$forceUpdate();
    });

    if(localStorage.getItem('importer-auth') !== 'bf:P5z#4n0}2kH[y') {
      this.$router.push({name: 'login'});
    }
  },
  methods: {
    async getAll(filter) {
      this.loading = true;
      await bannersService.getList(filter).then((response) => {
        this.items = response.data.map(item => {
          return {
            subsee_reference: item.subsee_reference ?? ' - ',
            client: {
              name: item.client ? item.client.name : 'Sem cliente definido'
            },
            start_date: item.start_date,
            end_date: item.end_date,
            clicks: item.clicks,
            views: item.views,
            conversion: `${item.conversion}%`,
            active_days: item.active_days,
            url: item.url
          };
        });
        this.loading = false;
      });
    },
    async loadClients(query) {
      await HighlightService.getClients(query).then((response) => {
        const {data} = response;
        this.clients = data.map((item) => {
          return {
            value: item.subsee_reference,
            name: item.subsee_reference + ' - ' + item.name
          }
        })
      });
      this.loading = false;
    },
    handleClearFilter() {
      this.filter = {
        advertiser: null,
      };
      this.getAll();
    },
    handleFilter() {

        this.getAll(this.filter);

    },
  },
  watch: {
    clientSearch: _.debounce(function(addr) {
      if(!addr) {
        this.filter.advertiser = null;
      }
      this.loadClients(addr)
    }, 500)
  }
}
</script>

<style scoped lang="scss">
.widget {
  border-radius: .32rem;
  padding: 10px;
  background: #6769f0;

  .title {
    font-weight: 600;
    color: #fff;
  }

  .value {
    font-weight: 600;
    font-size: 1.5rem;
    color: #fff;
  }
}
</style>